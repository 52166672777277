import React, { ReactChild, useEffect, useRef, useState } from 'react'
import { FiChevronRight, FiChevronDown } from 'react-icons/fi'
import { primaryColor } from 'components/constants'
import useOnResize from 'hooks/useOnResize'
import { Container, Title, TitleArea, ContentArea } from './styles'

interface ExpandableProps {
  title: string
  children: ReactChild
  forceCloseController: (forceClose: () => void) => void
  open?: boolean
}

const Expandable: React.FC<ExpandableProps> = ({
  title,
  children,
  forceCloseController,
  open = false,
}) => {
  const [expanded, setExpanded] = useState(open)
  const [contentArea, setContentArea] = useState(0)
  const contentRef = useRef<any>()
  const contentAreaClass = expanded ? 'show' : 'hide'

  const expandToggle = () => {
    // Provides the close controller to its parent
    if (expanded === false) {
      forceCloseController(() => {
        setExpanded(false)
      })
    }

    setExpanded(!expanded)
  }

  useOnResize(() => {
    setContentArea(0)
  }, true)

  useEffect(() => {
    if (contentRef.current && contentArea === 0) {
      setContentArea(contentRef.current.clientHeight)
    }
  }, [contentRef, contentArea])

  return (
    <Container>
      <TitleArea onClick={expandToggle}>
        <Title smallSize={14}>{title}</Title>
        {expanded ? (
          <FiChevronDown color={primaryColor} size={28} />
        ) : (
          <FiChevronRight color={primaryColor} size={28} />
        )}
      </TitleArea>
      <ContentArea
        area={contentArea}
        className={contentArea === 0 ? 'transparent' : contentAreaClass}
        ref={contentRef}
      >
        {children}
      </ContentArea>
    </Container>
  )
}

export default Expandable
