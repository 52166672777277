import H2 from 'components/H2'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
  width: 1284px;
  background-color: #FFF;
`

export const Title = styled(H2)`
  text-align: left;
  line-height: 32px;
`