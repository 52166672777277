import styled from 'styled-components'
import { fontFamily, textPrimaryColor, textSecondaryColor } from './constants'

interface Header2Props {
  secondary?: boolean
  margin?: string
  smallSize?: number
  mediumSize?: number
  largeSize?: number
  maxSize?: number
}

const H2 = styled.h2<Header2Props>`
  color: ${({ secondary }) => secondary ? textSecondaryColor : textPrimaryColor};
  font-weight: 400;
  line-height: 48px;
  margin: ${({margin}) => margin};
  font-family: ${fontFamily};
  font-size: ${({ smallSize }) => smallSize ? `${smallSize}px` : '36px'};

  @media(min-width: 375px) {
    font-size: ${({ mediumSize }) => mediumSize ? `${mediumSize}px` : '36px'};
    line-height: 52px;
  }

  @media(min-width: 820px) {
    font-size: ${({ largeSize }) => largeSize ? `${largeSize}px` : '40px'};
    line-height: 52px;
  }

  @media(min-width: 1120px) {
    font-size: ${({ maxSize }) => maxSize ? `${maxSize}px` : '56px'};
  }
`
export default H2
