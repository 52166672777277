import Max from 'components/Max'
import P from 'components/P'
import React from 'react'
// import howItWorksImage from 'images/lift-detox/how-it-works.jpeg'
// import howItWorksImage from 'images/lift-detox/ldc-a.jpeg'
import howItWorksImage from 'images/lift-detox/test.jpg'
// import howItWorksImage from 'images/lift-detox/test2.jpg'
import { Container, DescriptionContent, DescTitle, Image } from './styled'
import H2 from 'components/H2'
import Button from 'components/Button'
import goToAnchor from 'utils/goToAnchor'
import { dispatchAnalyticsEvent } from 'analytics'

interface ItemProps {
  title: string
  description: string
}

const Item: React.FC<ItemProps> = ({ title, description }) => {
  return (
    <>
      <li>
        <DescTitle>{title}</DescTitle>
      </li>
      <P smallSize={14} mediumSize={14} margin="0 0 14px">
        {description}
      </P>
    </>
  )
}

const HowItWorks: React.FC = () => {
  const onButtonClick = () => {
    dispatchAnalyticsEvent.click('how-it-works', 'howitworks-buy-anchor-cta')
    goToAnchor('kit-items')
  }

  return (
    <Max color="#FFFFFF" id="how-it-works">
      <Container>
        <Image src={howItWorksImage} alt="Como funciona" />
        <DescriptionContent>
          <H2 smallSize={26} mediumSize={26} largeSize={32} maxSize={32} margin="0">
            Como funciona
          </H2>
          <ul>
            <Item
              title="Acelera o metabolismo"
              description="O metabolismo acelerado queima calorias mais rapidamente, retardando o ganho de gordura."
            />

            <Item
              title="Diminui o apetite"
              description="Os ativos presentes em seus compostos diminuem o apetite e reduzem a absorção de açúcares e gorduras no organismo."
            />

            <Item
              title="Regula o intestino"
              description="As fribras farão com que seu intestino trabalhe de forma correta."
            />

            <Item
              title="Efeito barriga chapada"
              description="Por acelerar o metabolismo e queimar a gordura localizada, reduz drasticamente a gordura abdominal combatendo estrias e celulites"
            />

            <li style={{ listStyle: 'none' }}>
              <Button onClick={onButtonClick}>Experimente Agora</Button>
            </li>
          </ul>
        </DescriptionContent>
      </Container>
    </Max>
  )
}

export default HowItWorks
