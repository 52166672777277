import styled from 'styled-components'
import { fontFamily, primaryColor, primaryDisabledColor, secondaryColor } from './constants'

interface ButtonProps {
  disabled?: boolean
  bold?: boolean
  margin?: string
  large?: boolean
  secondary?: boolean
  fitContent?: boolean
  width?: string
}

const Button = styled.button<ButtonProps>`
  width: ${({ fitContent, width }) => fitContent ? 'fit-content' : width || ''};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: ${({ disabled, secondary }) => {
    if (disabled) {
      return `1px solid ${primaryDisabledColor}`
    } else {
      if (secondary) return `1px solid ${primaryColor}`
      return `1px solid ${primaryColor}`
    }
  }};
  background-color: ${({ disabled, secondary }) => {
    if (disabled) {
      if (secondary) return primaryColor
      return primaryDisabledColor
    } else {
      if (secondary) return secondaryColor
      return primaryColor
    }
  }};
  height: ${({ large }) => (large ? '48px' : '')};
  font-weight: ${({ bold }) => (bold ? '600' : '500')};
  font-size: 16px;
  margin: ${({ margin }) => (margin ? margin : '')};
  color: ${({ secondary }) => (secondary ? primaryColor : secondaryColor)};
  padding: ${({ large }) => (large ? '' : '8px 16px')};
  border-radius: ${({ large }) => (large ? '5px' : '2px')};
  line-height: ${({ large }) => (large ? '28px' : '24px')};
  font-family: ${fontFamily};
`
export default Button
