import React from 'react'
import Max from 'components/Max'
import { Container, Title, Paragraph } from './styles'
import { secondaryColor } from 'components/constants'
import Header from 'components/Header'
import Footer from 'sections/Footer'
import useToTopOnRenderPage from 'hooks/useToTopOnRenderPage'

const PolicyPrivacy: React.FC = () => {
  useToTopOnRenderPage()

  return (
    <>
      <Header basicVersion />
      <Max color={secondaryColor}>
        <Container>
          <Title smallSize={25} mediumSize={25} largeSize={25} maxSize={25}>
            Política de Privacidade
          </Title>
          <Paragraph>
            Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua
            visita no nosso site o mais produtiva e agradável possível.
          </Paragraph>
          <Paragraph>
            A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é
            muito importante para nós.
          </Paragraph>
          <Paragraph>
            Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes
            que usem o nosso site serão tratadas em concordância com a Lei da Proteção de Dados
            Pessoais de 26 de outubro de 1998 (Lei n.º 67/98).
          </Paragraph>
          <Paragraph>
            A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou
            telemóvel, morada, data de nascimento e/ou outros.
          </Paragraph>
          <Paragraph>
            O uso do site pressupõe a aceitação deste Acordo de privacidade. A equipe tem o direito
            de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa
            política de privacidade com regularidade de forma a estar sempre atualizado.
          </Paragraph>
          <Title smallSize={25} mediumSize={25} largeSize={25} maxSize={25}>
            Os Anúncios
          </Title>
          <Paragraph>
            Tal como outros websites, coletamos e utilizamos informação contida nos anúncios. A
            informação contida nos anúncios inclui o seu endereço IP (Internet Protocol), o seu ISP
            (Internet Service Provider, como o Sapo, Clix, ou outro), o browser que utilizou ao
            visitar o nosso website (como o Internet Explorer ou o Firefox), o tempo da sua visita e
            que páginas visitou dentro do nosso website.
          </Paragraph>
          <Title smallSize={25} mediumSize={25} largeSize={25} maxSize={25}>
            Os Cookies e Web Beacons
          </Title>
          <Paragraph>
            Utilizamos cookies para armazenar informação, tais como as suas preferências pessoas
            quando visita o nosso website. Isto poderá incluir um simples popup, ou uma ligação em
            vários serviços que providenciamos, tais como fóruns.
          </Paragraph>
          <Paragraph>
            Em adição também utilizamos publicidade de terceiros no nosso website para suportar os
            custos de manutenção. Alguns destes publicitários, poderão utilizar tecnologias como os
            cookies e/ou web beacons quando publicitam no nosso website, o que fará com que esses
            publicitários (como o Google através do Google AdSense) também recebam a sua informação
            pessoal, como o endereço IP, o seu ISP, o seu browser, etc. Esta função é geralmente
            utilizada para geotargeting (mostrar publicidade de Lisboa apenas aos leitores oriundos
            de Lisboa por exemplo) ou apresentar publicidade direcionada a um tipo de utilizador
            (como mostrar publicidade de restaurante a um utilizador que visita sites de culinária
            regularmente, por exemplo).
          </Paragraph>
          <Paragraph>
            Você detém o poder de desligar os seus cookies, nas opções do seu browser, ou efetuando
            alterações nas ferramentas de programas Anti-Virus, como o Norton Internet Security. No
            entanto, isso poderá alterar a forma como interage com o nosso website, ou outros
            websites. Isso poderá afetar ou não permitir que faça logins em programas, sites ou
            fóruns da nossa e de outras redes.
          </Paragraph>
          <Title smallSize={25} mediumSize={25} largeSize={25} maxSize={25}>
            Ligações a Sites de Terceiros
          </Title>
          <Paragraph>
            O site possui ligações para outros sites, os quais, a nosso ver, podem conter
            informações / ferramentas úteis para os nossos visitantes. A nossa política de
            privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a
            partir do nosso deverá ler a politica de privacidade do mesmo.
          </Paragraph>
          <Paragraph>
            Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses sites
            terceiros.
          </Paragraph>
        </Container>
      </Max>
      <Footer basicVersion hideTelegram />
    </>
  )
}

export default PolicyPrivacy
