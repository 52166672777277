import styled from 'styled-components'

interface FlexRowProps {
  margin?: string
}

const FlexRow = styled.div<FlexRowProps>`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => margin || ''}
`

export default FlexRow;