import React, { useEffect, useState } from 'react'
import { FiX } from 'react-icons/fi'
import GlobalPortal from '../../containers/GlobalPortal'
import Button from '../Button'
import Input from '../Input'
import Overlay from '../Overlay'
import { Description, DialogBody, DialogCloseButton, Title } from './styles'

interface Props {
  show?: boolean
  onClickOk: (text?: string) => void
  onClickClose: () => void
  title: string
  description: string
  buttonLabel?: string
}

const Dialog: React.FC<Props> = ({
  show,
  title,
  description,
  buttonLabel,
  onClickOk,
  onClickClose,
}) => {
  const [inputText, setInputText] = useState('')

  useEffect(() => {
    setInputText('')
  }, [show])

  if (!show) return null

  return (
    <GlobalPortal>
      <Overlay>
        <DialogBody>
          <DialogCloseButton onClick={onClickClose}>
            <FiX size={26} />
          </DialogCloseButton>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Input
            type="text"
            name="track"
            placeholder="Código de Rastreio"
            onChange={(e) => {
              setInputText(e.target.value)
            }}
          />
          <Button
            type="button"
            bold
            margin="50px 0 0"
            large
            onClick={() => {
              onClickOk(inputText)
            }}
          >
            {buttonLabel}
          </Button>
        </DialogBody>
      </Overlay>
    </GlobalPortal>
  )
}

export default Dialog
