import styled from "styled-components";
import { fontFamily, primaryColor, secondaryColor, textPrimaryColor } from "components/constants";

export const Wrapper = styled.div`
  position: relative;
  align-self: center;
  width: 95%;

  @media (min-width: 874px) {
    padding: 10px 0;
    width: auto;
  }
`

interface ButtonProps {
  active?: boolean
}

export const Button = styled.button<ButtonProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  width: auto;
  font-family: ${fontFamily};
  cursor: pointer;
  color: ${({active}) => active ? primaryColor : textPrimaryColor};

  &:hover {
    color: ${primaryColor};
  }

  font-size: 18px;
  font-weight: 400;
  align-self: center;
  margin: 10px 0;
  width: 95%;
  text-align: left;

  @media (min-width: 874px) {
    width: auto;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 0 26px;
  }
`

export const Links = styled.nav`
  background-color: ${secondaryColor};
  width: 260px;

  position: absolute;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  left: 16px;
  margin-top: 10px;

  & a {
    font-size: 14px;
    border: none;
    padding: 11px 16px;
    margin: 8px 0;
    text-align: left;
    line-height: 16px;
    display: block;
    font-family: ${fontFamily};
    text-decoration: none;
    color: ${textPrimaryColor};
    font-weight: 500;

    &:hover {
      text-decoration: none;
      background: #fafafa;
      color: ${primaryColor};
    }
  }
`