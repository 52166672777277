import H2 from "components/H2";
import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 16px;
  max-width: 1284px;
  width: calc(100% - 32px);

  @media(min-width: 820px) {
    padding: 50px 24px;
    width: calc(100% - 48px);
  }
`

export const Title = styled(H2)`
  line-height: 32px;
`

export const Content = styled.div`
  width: 338px;
  margin: auto;
  overflow-x: scroll;

  @media(min-width: 438px) {
    width: 396px;
  }

  @media(min-width: 820px) {
    width: 430px;
  }

   @media(min-width: 1025px) {
    width: 861px;
  }

  @media(min-width: 1288px) {
    width: 100%;
  }
`;