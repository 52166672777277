import {
  primaryColor,
  secondaryColor,
  secondaryFontFamily,
  tertiaryColor,
  textSecondaryColor,
  textTertiaryColor,
} from 'components/constants'
import H2 from 'components/H2'
import P from 'components/P'
import styled from 'styled-components'

export const Container = styled.div`
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  background: ${secondaryColor};
  box-shadow: 0 2px 7px 0 rgb(0 0 0 / 14%);
  margin: 0 16px;

  @media(min-width: 438px) {
    width: 361px;
  }

  @media(min-width: 820px) {
    width: 394px;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 365px;
  padding: 16px 0 0;

  @media(min-width: 438px) {
    flex-direction: row;
    padding: 8px 8px 0;
    height: 270px;
  }

  @media(min-width: 820px) {
    padding: 16px 16px 0;
  }
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 438px) {
    margin-right: 16px;
    width: 38%;
  }

  @media(min-width: 820px) {
    width: 32%;
  }
`

export const Image = styled.img`
  align-self: center;
  height: 129px;
  margin-bottom: 8px;
  margin-top: 12px;

  @media(min-width: 438px) {
    margin-bottom: 0;
    margin-top: 0;
    height: auto;

    width: 110px;
  }
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 438px) {
    width: 170px;
  }

  @media(min-width: 820px) {
    width: 205px;
  }
`

export const Title = styled(H2)`
  font-weight: bold;
  text-transform: uppercase;
  line-height: 20px;
  margin-top: 12px;

  @media(min-width: 438px) {
    margin-top: 0;
  }
`

export const Description = styled(P)`
  line-height: 20px;
  margin-top: 8px;
`

export const SubDescription = styled(P)`
  font-weight: 500;
  font-family: ${secondaryFontFamily};
  margin-top: 12px;

  text-align: center;

  @media(min-width: 438px) {
    text-align: left;
  }

  & span {
    text-decoration: line-through;
  }
`

export const PriceSimple = styled(P)`
  font-weight: bold;
  color: ${textTertiaryColor};
  align-self: flex-end;
  width: 100%;
  margin-top: -4px;
  margin-bottom: 6px;
  color: ${primaryColor};

  text-align: center;

  @media(min-width: 438px) {
    text-align: left;
  }

  & strong {
    color: ${textTertiaryColor};
    font-size: 18px;
    margin-right: 4px;
  }
`

export const PayOncePriceDescription = styled(P)`
  font-family: ${secondaryFontFamily};
  margin-top: 8px;
  margin-bottom: 8px;

  text-align: center;

  @media(min-width: 438px) {
    text-align: left;
  }

  & span {
    font-weight: bold;
  }
`

export const ShippingNotice = styled(P)`
  background: ${tertiaryColor};
  margin-bottom: 0;
  color: ${textSecondaryColor};
  text-align: center;
  font-weight: bold;
  padding: 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`
