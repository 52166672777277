import React from 'react'
import { FiCheckCircle } from 'react-icons/fi'
import Button from 'components/Button'
import {
  Container,
  Image,
  ItemContainer,
  ItemsText,
  LeftContent,
  Title,
  Paragraph,
  RightContent,
} from './styles'
import FlexRow from 'components/FlexRow'
import Max from 'components/Max'
import goToAnchor from 'utils/goToAnchor'
import { dispatchAnalyticsEvent } from 'analytics'

interface ItemProps {
  text: string
  isLastItem?: boolean
}
const Item: React.FC<ItemProps> = ({ text, isLastItem }) => {
  return (
    <FlexRow margin={isLastItem ? '0 0 22px' : '0 0 8px'}>
      <FiCheckCircle size={23} />
      <ItemsText margin="0 16px 0">{text}</ItemsText>
    </FlexRow>
  )
}

const AboutLargeImage: React.FC = () => {
  const onButtonClick = () => {
    dispatchAnalyticsEvent.click('about', 'about-buy-anchor-cta')
    goToAnchor('kit-items')
  }

  return (
    <Max color="#F7F5F8" id="about">
      <Container>
        <LeftContent>
          <Image />
        </LeftContent>
        <RightContent>
          <Title smallSize={26} mediumSize={26} largeSize={32} maxSize={42}>
            Efeito Bariátrica Natural
          </Title>
          <Paragraph margin="8px 0 24px">
            A fórmula do Lift Detox Caps é o resultado de 8 anos de pesquisa e estudos pensado para
            quem deseja emagrecer de maneira definitiva e livre do efeito sanfona. Aprovado em mais
            de 17 países como a verdadeira fórmula para emagrecer com saúde.{' '}
            <strong>Se você deseja:</strong>
          </Paragraph>
          <ItemContainer>
            <Item text="Emagrecer da maneira rápida e natural" />
            <Item text="Ativos 100% naturais e livre de efeitos colaterais" />
            <Item text="Finalmente usar as roupas que sempre quis" />
            <Item text="Reconquistar sua autoestima e qualidade" isLastItem />
          </ItemContainer>
          <Button onClick={onButtonClick}>Compre Agora e Desfrute dos Benefícios</Button>
        </RightContent>
      </Container>
    </Max>
  )
}

export default AboutLargeImage
