import React from 'react'
import Carousel from 'react-multi-carousel'
import Max from 'components/Max'
import ProductCard from 'components/ProductCard'
import { Container, Content, Title } from './styled'
import P from 'components/P'
import products from 'products.json'

import kit1 from 'images/lift-detox/kit1.png'
import kit2 from 'images/lift-detox/kit2.png'
import kit3 from 'images/lift-detox/kit3.png'
import kit5 from 'images/lift-detox/kit5.png'

import 'react-multi-carousel/lib/styles.css'
import './carousel.css'
import { sendLDCAnalytics } from 'analytics'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 9000, min: 1301 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1301, min: 1288 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1288, min: 1023 },
    items: 2,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 1024, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}

const KitItems: React.FC = () => {
  const onClickBuyButtonHandler = (goToUrl: string) => {
    switch (goToUrl) {
      case products.checkout1pote:
        sendLDCAnalytics.selectItem('1 Pote - Lift Detox Caps', 177, 80)
        break
      case products.checkout2potes:
        sendLDCAnalytics.selectItem('2 Potes - Lift Detox Caps', 194, 37)
        break
      case products.checkout3potes:
        sendLDCAnalytics.selectItem('3 Potes - Lift Detox Caps', 297, 70)
        break
      case products.checkout5potes:
        sendLDCAnalytics.selectItem('5 Potes - Lift Detox Caps', 497, 150)
        break
    }

    window.open(goToUrl, '_self')
  }

  return (
    <Max color="#F2F0ED" id="kit-items">
      <Container>
        <Title smallSize={26} mediumSize={26} largeSize={32} maxSize={42} margin="0 0 16px">
          Escolha o Kit Ideal Para Você
        </Title>
        <P margin="0">
          Recomendamos o tratamento de 3 a 5 meses para melhores resultados. Se não houver
          resultados em 30 dias, você pode devolver!
        </P>
        <P smallSize={12} mediumSize={12} largeSize={12} maxSize={12} margin="8px 0 48px">
          <b>*Você terá até 30 dias após a compra para solicitar a devolução.</b>
        </P>
        <Content>
          <Carousel
            showDots={true}
            responsive={responsive}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            <ProductCard
              image={kit1}
              title="Compre 1 Pote"
              description="Tratamento Para 1 Mês"
              originalPrice="R$177,00"
              timesPrice="R$9,74"
              onePayPrice="R$97,00"
              buyButtonLabel="Comprar Agora"
              shippingNoticeLabel="45% OFF + FRETE GRÁTIS"
              onClickButton={() => onClickBuyButtonHandler(products.checkout1pote)}
            />

            <ProductCard
              image={kit2}
              title="Compre 2 Potes"
              description="Tratamento Para 2 Meses"
              originalPrice="R$194,00"
              timesPrice="R$15,76"
              onePayPrice="R$157,00"
              buyButtonLabel="Comprar Agora"
              shippingNoticeLabel="20% OFF + FRETE GRÁTIS"
              onClickButton={() => onClickBuyButtonHandler(products.checkout2potes)}
            />

            <ProductCard
              image={kit3}
              title="Compre 3 Potes"
              description="Tratamento Para 3 Meses"
              originalPrice="R$297,00"
              timesPrice="R$22,79"
              onePayPrice="R$227,00"
              buyButtonLabel="Comprar Agora"
              shippingNoticeLabel="23% OFF + FRETE GRÁTIS"
              onClickButton={() => onClickBuyButtonHandler(products.checkout3potes)}
            />

            <ProductCard
              image={kit5}
              title="Compre 5 Potes"
              description="Tratamento Para 5 Meses"
              originalPrice="R$497,00"
              timesPrice="R$34,84"
              onePayPrice="R$347,00"
              buyButtonLabel="Comprar Agora"
              shippingNoticeLabel="30% OFF + FRETE GRÁTIS"
              onClickButton={() => onClickBuyButtonHandler(products.checkout5potes)}
            />
          </Carousel>
        </Content>
      </Container>
    </Max>
  )
}

export default KitItems
