import styled from 'styled-components'
import H2 from 'components/H2';

export const Container = styled.div`
  padding: 50px 16px;
  max-width: 1284px;
  width: calc(100% - 32px);

  @media(min-width: 820px) {
    padding: 50px 24px;
    width: calc(100% - 48px);
  }
`;

export const Title = styled(H2)`
  text-align: center;
  margin-top: 0;
  line-height: 20px;
  margin-bottom: 24px;

  @media(min-width: 820px) {
    text-align: left;
    line-height: 52px;
  }
`