import styled from 'styled-components'
import P from 'components/P';

export const Container = styled.div`
  padding-bottom: 58px;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 298px;
  height: 454px;
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  margin: auto;
  overflow: hidden;
`;

interface ImageProps {
  src: string,
  horizontalAdjustment?: number
}

export const Image = styled.div<ImageProps>`
  width: 100%;
  height: 264px;
  background: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: ${({horizontalAdjustment}) => horizontalAdjustment ? `${horizontalAdjustment}px` : 'center'};
  background-repeat: no-repeat;
`

export const Description = styled(P)`
  font-style: italic;
  margin: 0;
  padding: 16px 16px 0;
`

export const Author = styled(P)`
  margin: 12px 0 0;
  padding: 0 16px 16px;
  font-weight: 500;
`