import styled from 'styled-components'

interface ContainerProps {
  bgColor: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  height: 72px;
  width: 100%;
  background-color: ${({ bgColor }) => bgColor};

  & img {
    height: 43px;
    align-self: center;
    opacity: 0.7;
  }
`