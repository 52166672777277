import { primaryColor, secondaryColor, secondaryFontFamily } from 'components/constants'
import H2 from 'components/H2'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 1284px;
  padding: 68px 24px;

  @media(min-width: 784px) {
    flex-direction: row;
  }
`

export const Title = styled(H2)`
  font-family: ${secondaryFontFamily};
`

export const Contact = styled.a`
  font-family: ${secondaryFontFamily};
  font-size: 22px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  color: ${primaryColor};
  cursor: pointer;
  text-decoration: none;
  margin-top: 8px;

  &:hover {
    color: ${secondaryColor}
  }

  @media(min-width: 820px) {
    font-size: 24px;
  }

  & svg {
    margin-top: 2px;
    margin-right: 8px;
  }
`

export const HelpSection = styled.div`
  width: 100%;
  margin-bottom: 48px;

  @media(min-width: 784px) {
    margin-bottom: 0;
    width: 20%;
  }
`

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 48px;

  & a, p, button {
    background: transparent;
    border: none;
    text-align: left;
    padding: 0;

    cursor: pointer;
    margin-top: 0;
    text-decoration: none;
    font-family: ${secondaryFontFamily};
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${secondaryColor};
    margin-bottom: 18px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  @media(min-width: 784px) {
    margin-bottom: 0;
    width: 20%;
  }
`

export const InfoSection = styled.div`
  width: 100%;

@media(min-width: 784px) {
  width: 40%;
}
`