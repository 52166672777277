import React, { useEffect } from 'react';

/**
 * This hook tell if a click was dispatched outside of the element
 * @param {HTMLElement} elementRef HTML Element to be verified
 * @param {() => void} handler Callback called when a click is detected outside the element
 *
 * Example:
 *
 * const myBoxRef = useRef();
 * useOnClickOutOfElement(aboutBoxRef, () => { console.log('clicked outside myBoxRef element') });
 */
const useOutsideClick = (elementRef: React.MutableRefObject<any>, handler: () => void) => {
  useEffect(() => {
    const clickOutOfElementHandler = (event: any) => {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        if (handler) handler();
      }
    };

    window.addEventListener('click', clickOutOfElementHandler);

    return () => {
      window.removeEventListener('click', clickOutOfElementHandler);
    };
  }, [elementRef, handler]);
};

export default useOutsideClick;