import React from 'react'
import P from 'components/P'
import Max from 'components/Max'
import spirulina from 'images/lift-detox/spirulina.png'
import vitaminae from 'images/lift-detox/vitamina-e.png'
import guarana from 'images/lift-detox/guarana.png'
import cromo from 'images/lift-detox/cromo.png'
import quitosana from 'images/lift-detox/quitosana.png'
import psyllium from 'images/lift-detox/psyllium.png'
import { Container, ItemContainer, ItemImage, ItemLeft, ItemRight, Title } from './styles'

interface ItemProps {
  imageSrc: string
  title: string
  text: string
}

const Item: React.FC<ItemProps> = ({ imageSrc, title, text }) => {
  return (
    <ItemContainer>
      <ItemLeft>
        <ItemImage src={imageSrc} />
      </ItemLeft>
      <ItemRight>
        <Title smallSize={14} mediumSize={14} largeSize={14} maxSize={14}>
          {title}
        </Title>
        <P margin="0">{text}</P>
      </ItemRight>
    </ItemContainer>
  )
}

const Formules: React.FC = () => {
  return (
    <Max>
      <Container>
        <Item
          imageSrc={spirulina}
          title="Spirulina"
          text="Fonte de energia, vitamina e minerais. Fortalece o sistema imunológico, controla pressão e o colesterol e melhora a performance muscular."
        />

        <Item
          imageSrc={vitaminae}
          title="Vitamina E"
          text="Antioxidante poderoso! A vitamina E é um dos segredos para cuidar do colesterol e gordura do fígado já que a mesma é responsável por eliminar as gorduras."
        />

        <Item
          imageSrc={guarana}
          title="Guaraná"
          text="Extraído da Amazônia ele auxilia o organismo a expulsar a gordura agindo como um combustível para o corpo, fornecendo muito mais energia e disposição para o dia-a-dia."
        />

        <Item
          imageSrc={cromo}
          title="Cromo"
          text="Age diretamente como um poderoso inibidor de apetite, trazendo saciedade e rápidos resultados na balança."
        />

        <Item
          imageSrc={quitosana}
          title="Quitosana"
          text="Remédio Natural responsável por eliminar a gordura do corpo e regular o trânsito intestinal."
        />

        <Item
          imageSrc={psyllium}
          title="Psyllium"
          text="Rico em fibra o psyllium auxilia na perda de peso, no controle do colesterol e na redução da pressão arterial."
        />
      </Container>
    </Max>
  )
}

export default Formules
