import { useEffect } from "react";
import scrollToTop from "utils/scrollToTop";

const useToTopOnRenderPage = () => {
  useEffect(() => {
    scrollToTop(true);
  }, [])
}

export default useToTopOnRenderPage;
