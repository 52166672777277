import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 58px;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 304px;
  height: 548px;
  border: 1px solid #E0E0E0;
  border-radius: 2px;
  margin: auto;
  overflow: hidden;
  padding-top: 9px;
`;

interface ImageProps {
  src: string,
}

export const Image = styled.div<ImageProps>`
  height: 100%;
  background: ${({ src }) => `url(${src})`};
  background-size: contain;
  background-repeat: no-repeat;
`