import styled from 'styled-components'
import { fontFamily, primaryColor, textPrimaryColor } from '../constants'

interface HeaderLogoProps {
  hideMobile?: boolean
  hideDesktop?: boolean
}

export const HeaderLogo = styled.header<HeaderLogoProps>`
  padding: 0;
  top: 0;
  position: fixed;
  height: 70px;
  width: 100%;
  background-color: #ffffff;
  align-items: center;
  height: auto;
  flex-direction: column;
  border-bottom: 1px solid #e0e0e0;
  display: ${({ hideMobile }) => (hideMobile ? 'none' : 'flex')};
  z-index: ${({ hideDesktop }) => (hideDesktop ? 'auto' : '1001')};

  @media (min-width: 874px) {
    flex-direction: row;
    padding: 0 25px;
    height: 70px;
    width: calc(100% - 50px);
  }
`

export const MobileControl = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 40px);
  align-self: flex-start;
  margin: 10px 15px 10px 25px;

  @media (min-width: 874px) {
    justify-self: center;
    align-self: center;
    width: fit-content;
    margin: 0px 26px 0 0;
  }
`

export const LogoLeafWrapper = styled.div`
  position: fixed;
  top: 12px;
  left: 68px;
  z-index: 1;
  cursor: pointer;
`

export const Logo = styled.img`
  height: 40px;
  margin-top: 6px;
  opacity: 0.9;
  cursor: pointer;
`

export const ProductTitle = styled.h3`
  font-family: ${fontFamily};
  color: ${textPrimaryColor};
  margin: 0;
  align-self: center;
  font-weight: 500;
  font-size: 22px;
`

export const MobileMenuButton = styled.div`
  display: block;
  align-self: center;
  padding: 10px;
  @media (min-width: 874px) {
    display: none;
  }
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media (min-width: 874px) {
    justify-content: space-between;
    flex-direction: row;
  }
`

interface MenuContentProps {
  mobileBorder?: boolean
  showMobileMenuItems?: boolean
}

export const MenuContent = styled.ul<MenuContentProps>`
  display: ${({ showMobileMenuItems }) => (showMobileMenuItems ? 'flex' : 'none')};
  flex-direction: column;
  padding: 10px 0;
  margin: 0;
  width: 100%;
  border-top: ${({ mobileBorder }) => (mobileBorder ? '1px solid #e0e0e0' : '')};

  @media (min-width: 874px) {
    display: flex;
    flex-direction: row;
    border-top: none;
    width: auto;
  }
`

interface MenuItemProps {
  asButton?: boolean
}

export const MenuItem = styled.li<MenuItemProps>`
  list-style: none;
  display: flex;
  justify-content: center;

  & .track-button {
    width: 95%;
    margin: 10px 0;

    @media (min-width: 874px) {
      width: 100%;
    }
  }

  & .button-item {
    border: none;
    background: transparent;
    padding: 0;
    text-align: left;

    font-size: 18px;
    font-weight: 400;
    align-self: center;
    font-family: ${fontFamily};
    margin: 10px 0;
    width: 95%;
    display: block;
    cursor: ${({ asButton }) => (asButton ? 'pointer' : 'initial')};

    @media (min-width: 874px) {
      font-weight: 500;
      margin-left: 26px;
      font-size: 14px;
      width: auto;
    }

    &:hover {
      color: ${primaryColor};
    }
  }
`
export const HeaderMargin = styled.div`
  margin-top: 70px;
`
