import styled from "styled-components";
import heroImage from "images/lift-detox-hero.png"
import P from "components/P";
import H2 from "components/H2";

export const HeroBody = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  width: 1284px;
  height: 100%;
  background-color: #21012D;

  @media(min-width: 820px) {
    flex-direction: row;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;

  @media(min-width: 820px) {
    width: 50%;
    padding: 50px 24px;
  }
`;

export const Right = styled.div`
  width: 80%;
  height: 200px;
  background-image: url(${heroImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 18px;

  @media(min-width: 375px) {
    height: 300px;
  }

  @media(min-width: 820px) {
    margin-top: 0;
    background-size: cover;
    width: 400px;
    height: 400px;
  }
`;

export const Title = styled(H2)`
  text-align: center;
  @media(min-width: 820px) {
    text-align: left;
  }
`

export const Paragraph = styled(P)`
  text-align: center;
  @media(min-width: 820px) {
    text-align: left;
  }
`

export const HeroButtonWrapper = styled.div`
  align-self: center;

  @media(min-width: 820px) {
    align-self: flex-start;
  }
`