import React, { useState } from 'react'
import { FiX, FiMenu } from 'react-icons/fi'
import { FaLeaf } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@react-hook/media-query'
import goToAnchor from 'utils/goToAnchor'
import logo from 'images/main-logo.png'
import Button from '../Button'
import Dialog from '../Dialog'
import { primaryColor } from 'components/constants'
import {
  MobileMenuButton,
  HeaderLogo,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MobileControl,
  HeaderMargin,
  LogoLeafWrapper,
  ProductTitle,
} from './styles'
import Dropdown from 'components/Dropdown'
import { othersDropdown } from './constants'
import { dispatchAnalyticsEvent } from 'analytics'

interface MenuItemButtonProps {
  to?: string
  toAnchor?: string
}

const MenuItemButton: React.FC<MenuItemButtonProps> = ({ children, to, toAnchor }) => {
  const navigate = useNavigate()

  const navigateAction = (to: string) => {
    dispatchAnalyticsEvent.click('nav', children!.toString())
    navigate(to)
  }

  const goToAnchorAction = (toAnchor: string) => {
    dispatchAnalyticsEvent.click('nav', children!.toString())
    goToAnchor(toAnchor)
  }

  return (
    <MenuItem asButton>
      {to ? (
        <button type="button" className="button-item" onClick={() => navigateAction(to || '#')}>
          {children}
        </button>
      ) : (
        <button
          type="button"
          className="button-item"
          onClick={() => goToAnchorAction(toAnchor || '')}
        >
          {children}
        </button>
      )}
    </MenuItem>
  )
}

interface HeaderProps {
  basicVersion?: boolean
}

const Header: React.FC<HeaderProps> = ({ basicVersion }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [showProductTitle] = useState(true)
  const isMobile = useMediaQuery('(max-width:873px)')
  const navigate = useNavigate()

  const toggleDialog = () => {
    setShowDialog(!showDialog)
    if (showDialog) {
      setShowMobileMenu(false)
    }
  }

  const goToTrackerSite = (trackInfo?: string) => {
    toggleDialog()
    if (trackInfo) window.open(`https://rastreamentocorreios.info/consulta/${trackInfo}`, '_blank')
  }

  const onClickMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const onClickMenuBody = () => {
    if (showMobileMenu) {
      onClickMobileMenu()
    }
  }

  return (
    <>
      <HeaderLogo hideMobile={showDialog && isMobile} hideDesktop={showDialog && !isMobile}>
        <MobileControl>
          <LogoLeafWrapper onClick={() => navigate('/')}>
            <FaLeaf color={primaryColor} size={24} />
          </LogoLeafWrapper>
          <Logo src={logo} alt="Lift Saúde" onClick={() => navigate('/')} />
          {showProductTitle && isMobile && <ProductTitle>Lift Detox</ProductTitle>}
          <MobileMenuButton onClick={onClickMobileMenu}>
            {showMobileMenu ? <FiX size={26} /> : <FiMenu size={26} />}
          </MobileMenuButton>
        </MobileControl>
        <Menu>
          <MenuContent mobileBorder showMobileMenuItems={showMobileMenu} onClick={onClickMenuBody}>
            {!basicVersion ? (
              <>
                <MenuItemButton toAnchor="kit-items">Ganhe 45% Off</MenuItemButton>
                <MenuItemButton toAnchor="how-it-works">Como Funciona</MenuItemButton>
                <MenuItemButton toAnchor="about">Fórmula</MenuItemButton>
                <MenuItemButton toAnchor="member-result">Testemunhas</MenuItemButton>
                <MenuItemButton toAnchor="faq">
                  {isMobile ? 'Perguntas Frequentes' : 'FAQ'}
                </MenuItemButton>

                <Dropdown {...othersDropdown} />
                {isMobile && (
                  <>
                    <MenuItemButton to="/politica-de-privacidade">
                      Política de Privacidade
                    </MenuItemButton>
                    <MenuItemButton to="/termos-e-condicoes">Termos e Condições</MenuItemButton>
                  </>
                )}
              </>
            ) : (
              <>
                <MenuItemButton to="/">Home</MenuItemButton>
                <Dropdown {...othersDropdown} />
                {isMobile && (
                  <>
                    <MenuItemButton to="/politica-de-privacidade">
                      Política de Privacidade
                    </MenuItemButton>
                    <MenuItemButton to="/termos-e-condicoes">Termos e Condições</MenuItemButton>
                  </>
                )}
              </>
            )}
          </MenuContent>
          <MenuContent showMobileMenuItems={showMobileMenu}>
            <MenuItem>
              <Button secondary width="100%" className="track-button" onClick={toggleDialog}>
                Rastrear
              </Button>
            </MenuItem>
          </MenuContent>
        </Menu>
      </HeaderLogo>

      <Dialog
        title="Rastrear"
        description="Digite o código de rastreio:"
        buttonLabel="Rastrear Pedido"
        onClickOk={goToTrackerSite}
        onClickClose={toggleDialog}
        show={showDialog}
      />

      <HeaderMargin />
    </>
  )
}

export default Header
