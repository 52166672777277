import P from "components/P";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  border-bottom: 1px solid #dadada;
`

export const TitleArea = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  & svg {
    margin-top: 12px;
  }
`

export const Title = styled(P)`
  font-weight: bold;
`

interface ContentAreaProps {
  area: number
}

export const ContentArea = styled.div<ContentAreaProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  transition: height 0.5s;

  &.hide {
    height: 0;
  }

  &.show {
    height: ${({ area }) => `${area}px`};
    opacity: 1;
  }

  &.transparent {
    opacity: 0;
  }
`