import React from 'react'
import Header from 'components/Header'
import LogosBar from 'sections/LogosBar'
import HeroSection from 'sections/Hero'
import HowItWorks from 'sections/HowItWorks'
import AboutLargeImage from 'sections/AboutLargeImage'
import Formules from 'sections/Formules'
import KitItems from 'sections/KitItems'
import Tip from 'sections/Tip'
import MemberResult from 'sections/MemberResult'
import InfoBar from 'sections/InfoBar'
import Deliveries from 'sections/Deliveries'
import FAQ from 'sections/FAQ'
import Footer from 'sections/Footer'
import { secondaryColor } from 'components/constants'
import useToTopOnRenderPage from 'hooks/useToTopOnRenderPage'

const LandingPage: React.FC = () => {
  useToTopOnRenderPage()

  return (
    <>
      <Header />
      <HeroSection />
      <LogosBar />
      <HowItWorks />
      <AboutLargeImage />
      <Formules />
      <KitItems />
      <Tip />
      <MemberResult />
      <Deliveries />
      <LogosBar showButton hideImage bgColor={secondaryColor} />
      <InfoBar />
      <FAQ expandOnlyOne />
      <Footer hideTelegram />
    </>
  )
}

export default LandingPage
