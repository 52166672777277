import { getAnalytics, logEvent } from '@firebase/analytics'
import { firebase } from 'firebaseApp'
import isProduction from 'utils/isProduction'

const analytics = getAnalytics(firebase)

export const logAlyticsEvent = (eventName: string, payload: {} | undefined) => {
  logEvent(analytics, eventName, payload)
}

export enum AffiliationProgram {
  Monetize = 'Monetize',
  Hotmart = 'Hotmart',
}

export enum Category {
  Health = 'Health',
}

export const dispatchAnalyticsEvent = {
  click: (section: string, buttonName: string) =>
    logAlyticsEvent('click', { section, button_name: buttonName, prod_env: isProduction }),
  selectItem: (
    itemName: string,
    price: number,
    discount: number,
    affiliation: AffiliationProgram,
    itemBrand: string,
    category: Category,
    isBlackProduct: boolean = false
  ) =>
    logAlyticsEvent('buy_now', {
      item_name: itemName,
      discount,
      affiliation,
      item_brand: itemBrand,
      item_category: category,
      item_variant: isBlackProduct ? 'black' : 'white',
      price,
      final_price: price - discount,
      currency: 'BRL',
      quantity: 1,
      prod_env: isProduction,
    }),
}

export const sendLDCAnalytics = {
  selectItem: (itemName: string, price: number, discount: number) =>
    dispatchAnalyticsEvent.selectItem(
      itemName,
      price,
      discount,
      AffiliationProgram.Monetize,
      'Lift Detox Caps',
      Category.Health,
      false
    ),
}
