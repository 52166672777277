const scrollToAnchor = (anchorId: string, verticalMargin = 0, smooth = false) => {
  const element = document.getElementById(anchorId);

  if (element) {
    window.scrollTo({
      behavior: smooth ? 'smooth' : 'auto',
      top: element.offsetTop + verticalMargin
    });
  }
};

export default scrollToAnchor;
