import React from 'react'
import Max from 'components/Max'
import DeliveriesCard from 'components/DeliveriesCard'
import { Container, Title } from './styled'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './carousel.css'

import entrega01 from 'images/lift-detox/entrega-01.jpeg'
import entrega02 from 'images/lift-detox/entrega-02.jpeg'
import entrega03 from 'images/lift-detox/entrega-03.jpeg'
import entrega04 from 'images/lift-detox/entrega-04.jpeg'

const images = [entrega01, entrega02, entrega03, entrega04]

const Deliveries: React.FC = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 9000, min: 1301 },
      items: 3, // Coloca 4 caso tenha mais cards
    },
    desktop: {
      breakpoint: { max: 1301, min: 994 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 994, min: 670 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 670, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  return (
    <Max id="member-result">
      <Container>
        <Title smallSize={18} mediumSize={18} largeSize={24} maxSize={24}>
          Milhares de entregas
        </Title>
        <Carousel showDots={true} responsive={responsive}>
          {images.map((image) => (
            <DeliveriesCard imageSrc={image} key={image} />
          ))}
        </Carousel>
      </Container>
    </Max>
  )
}

export default Deliveries
