import React from 'react'
import Max from 'components/Max'
import Expandable from 'components/Expandable'
import P from 'components/P'
import { Container, Title } from './styles'

interface FAQProps {
  expandOnlyOne?: boolean
  firstOpen?: boolean
}

let closeLastExpandable: () => void = () => {}

const FAQ: React.FC<FAQProps> = ({ expandOnlyOne, firstOpen = false }) => {
  // This is used to close the last expandable open when another expandable is open
  const closeExpandableProvider = (close: () => void) => {
    if (expandOnlyOne) {
      if (closeLastExpandable) {
        closeLastExpandable()
      }
      closeLastExpandable = close
    }
  }

  return (
    <Max color="#FFF" id="faq">
      <Container>
        <Title smallSize={26} mediumSize={26} largeSize={32} maxSize={42} margin="16px 0">
          FAQs - Perguntas Frequentes
        </Title>
        <Expandable
          title="Quais os efeitos do Lift Detox Caps?"
          forceCloseController={closeExpandableProvider}
          open={firstOpen}
        >
          <P>
            Possui benefícios para a melhora da saúde, colaborando na regulação do intestino,
            promovendo saciedade, controlando apetite, desintoxicando o corpo e consequentemente
            potencializando os resultados na busca de um corpo saudável e magro. possui uma ação
            emagrecedora, atuando na queima de gordura, desintoxicação do corpo, auxiliando na
            redução do inchaço do seu corpo, aumentando sua disposição e fazendo você emagrecer de
            forma rápida e definitiva.
          </P>
        </Expandable>

        <Expandable
          title="Com quanto tempo eu terei resultados?"
          forceCloseController={closeExpandableProvider}
        >
          <P>Resultados podem variar de acordo com o organismo de cada pessoa.</P>
        </Expandable>

        <Expandable
          title="O Lift Detox Caps vai funcionar em mim?"
          forceCloseController={closeExpandableProvider}
        >
          <P>
            As substâncias ativas encontradas no Lift Detox Caps vão desintoxicar o seu corpo,
            eliminar as toxinas e estimular o seu metabolismo e organismo, proporcionando assim, o
            bem estar definitivo, a queima de gordura localizada e a restauração da saúde do corpo.
            Com Lift Detox Caps você já consegue ver diferença já nas primeiras semanas de uso.
          </P>
        </Expandable>

        <Expandable
          title="Qual o prazo da entrega do produto?"
          forceCloseController={closeExpandableProvider}
        >
          <P>
            O prazo médio de entrega do Lift Detox Caps é de 7 a 20 dias úteis{' '}
            <b>APÓS A POSTAGEM</b> do pedido, finais de semana e feriado não é contabilizados. O
            envio é pelo Correios. <b>FRETE GRÁTIS PARA TODO O BRASIL!</b> O prazo passa a valer
            após a confirmação do seu pagamento pela administradora do seu cartão de crédito ou após
            a confirmação do pagamento do boleto.
          </P>
        </Expandable>

        <Expandable title="Como devo usar?" forceCloseController={closeExpandableProvider}>
          <P>Indicado tomar Lift Detox Caps 2 vezes ao dia antes de cada refeição.</P>
        </Expandable>

        <Expandable title="O site é seguro?" forceCloseController={closeExpandableProvider}>
          <P>
            Sim, trabalhamos com selo certificado de segurança SSL. Certificado SSL é um certificado
            digital que autentica a identidade de um site e possibilita uma conexão criptografada. O
            termo "SSL" significa "Secure Sockets Layer" (camada de soquete seguro), um protocolo de
            segurança que cria um link criptografado entre um servidor Web e um navegador Web.
          </P>
        </Expandable>

        <Expandable
          title="Os benefícios são comprovados cientificamente?"
          forceCloseController={closeExpandableProvider}
        >
          <P>
            Sim. Os ingredientes que compõe o Lift Detox Caps possuem propriedades nutricionais como
            algas, vitaminas, minerais, fibras, aminoácidos, fitonutrientes e formulações detox que
            são essenciais para o perfeito funcionamento do organismo. Lift Detox Caps funciona e
            possui benefícios para a queima de gordura e melhora da saúde, colaborando na regulação
            do intestino, promovendo saciedade, desintoxicando o corpo e consequentemente gerando os
            resultados de um corpo saudável e magro.
          </P>
        </Expandable>

        <Expandable
          title="Lift Detox Caps é aprovado pela ANVISA?"
          forceCloseController={closeExpandableProvider}
        >
          <P>
            Por se tratar de um Suplemento alimentar, Lift Detox Caps é um produto dispensado da
            obrigatoriedade de registro conforme RDC 27/2010.
          </P>
        </Expandable>
      </Container>
    </Max>
  )
}

export default FAQ
