import styled from "styled-components";
import H2 from "components/H2";
import P from "components/P";
import peso from 'images/lift-detox/peso.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 14px;
  background: #F7F5F8;
  max-width: 1284px;

  @media (min-width: 820px) {
    flex-direction: row;
  }

  @media (min-width: 1000px) {
    padding: 0 92px;
  }
`

export const Title = styled(H2)`
  margin: 16px 0 0;

  @media (min-width: 820px) {
    margin: 0;
  }
`

export const Paragraph = styled(P)`
  text-align: center;

  @media (min-width: 820px) {
    text-align: left;
  }
`

export const ItemsText = styled(P)`
  text-align: center;
  font-weight: bold;
  text-align: left;
`

export const ItemContainer = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  /* padding: 16px 0 0; */

  @media (min-width: 820px) {
    align-items: flex-start;
    width: 50%;
    padding: 72px 0;
  }
`

export const LeftContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
`

export const Image = styled.div`
  width: 184px;
  height: 184px;
  background-image: url(${peso});
  background-size: cover;
  border-radius: 30%;

  @media (min-width: 820px) {
    width: 320px;
    height: 73%;
    border-radius: 0;
  }

  @media (min-width: 1000px) {
    width: 385px;
    height: 100%;
  }

  @media (min-width: 1120px) {
    width: 337px;
  }
`