import styled from 'styled-components'
import { fontFamily, secondaryFontFamily } from '../constants'

export const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 64px);
  padding: 0 32px 32px;
  border-radius: 5px;
  background: rgb(244, 240, 236);

  @media (min-width: 820px) {
    padding: 0 32px 32px;
    width: 460px;
    height: auto;
    margin-top: 100px;
  }
`

export const DialogCloseButton = styled.button`
  align-self: flex-end;
  margin-top: 20px;
  padding: 0;
  border: none;
  cursor: pointer;
`

export const Title = styled.h3`
  color: rgb(33, 33, 33);
  font-weight: 500;
  font-size: 48px;
  line-height: 52px;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-family: ${secondaryFontFamily};
`

export const Description = styled.p`
  color: rgb(33, 33, 33);
  font-size: 14px;
  font-weight: 500;
  font-family: ${fontFamily};
`
