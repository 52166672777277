import React from 'react'
import Button from 'components/Button'
import goToAnchor from 'utils/goToAnchor'
import selosImage from 'images/selos-de-compra.png'
import { Container } from './styles'
import { dispatchAnalyticsEvent } from 'analytics'

interface LogosBarProps {
  showButton?: boolean
  hideImage?: boolean
  bgColor?: string
}

const LogosBar: React.FC<LogosBarProps> = ({
  showButton = false,
  hideImage = false,
  bgColor = '#f5f5f5',
}) => {
  const onButtonClick = () => {
    dispatchAnalyticsEvent.click('member-result', 'memberresult-buy-anchor-cta')
    goToAnchor('kit-items')
  }

  return (
    <Container bgColor={bgColor}>
      {showButton && (
        <div>
          <Button onClick={onButtonClick}>Peça já seu Lift Detox Caps!</Button>
        </div>
      )}
      {!hideImage && <img src={selosImage} alt="Selos de compra" />}
    </Container>
  )
}

export default LogosBar
