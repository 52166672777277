import { tertiaryColor } from "components/constants";
import P from "components/P";
import styled from "styled-components";
import modelo from "images/lift-detox/modelo.png";
import H2 from "components/H2";

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding: 40px 24px 16px;
  background: ${tertiaryColor};
  max-width: 1284px;

  @media (min-width: 820px) {
    flex-direction: row;
    padding: 0 24px;
  }
`

export const Title = styled(H2)`
  line-height: 26px;
  margin-bottom: 18px;
  text-align: center;

  @media (min-width: 820px) {
    line-height: 52px;
    text-align: left;
    margin-bottom: 16px 0 0;
  }
`

export const Paragraph = styled(P)`
  text-align: center;

  @media (min-width: 820px) {
    text-align: left;
  }
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  padding: 16px 0 0;

  @media (min-width: 820px) {
    align-items: flex-start;
    width: 50%;
    padding: 72px 0;
  }
`

export const RightContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
`

export const Image = styled.div`
  width: 184px;
  height: 184px;
  background-image: url(${modelo});
  background-size: cover;
  border-radius: 30%;

  @media (min-width: 820px) {
    width: 320px;
    height: 73%;
    border-radius: 0;
  }

  @media (min-width: 1000px) {
    width: 385px;
    height: 100%;
  }

  @media (min-width: 1120px) {
    width: 365px;
  }
`