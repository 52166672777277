import React from 'react'
import Button from 'components/Button'
import { HeroBody, Left, Right, Paragraph, Title, HeroButtonWrapper } from './styles'
import Max from 'components/Max'
import { tertiaryColor } from 'components/constants'
import goToAnchor from 'utils/goToAnchor'
import { dispatchAnalyticsEvent } from 'analytics'

const HeroSection: React.FC = () => {
  const onButtonClick = () => {
    dispatchAnalyticsEvent.click('hero', 'hero-buy-anchor-cta')
    goToAnchor('kit-items')
  }

  return (
    <Max color={tertiaryColor} id="hero">
      <HeroBody>
        <Left>
          <Title secondary margin="0" maxSize={48}>
            Facilite seu processo de emagrecimento.
          </Title>
          <Paragraph secondary margin="12px 0" smallSize={16}>
            Lift Detox Caps possui fórmula 100% natural que agilizam o processo de emagrecimento,
            sem precisar sair de casa.
          </Paragraph>
          <HeroButtonWrapper>
            <Button fitContent margin="32px 0 0" onClick={onButtonClick}>
              Experimente Agora Por Apenas R$9,74
            </Button>
          </HeroButtonWrapper>
          <Paragraph secondary margin="8px 0 0" smallSize={13}>
            Compre 1 pote para tratamento de 1 mês.
          </Paragraph>
          <Paragraph
            secondary
            margin="-8px 0 0"
            smallSize={13}
            mediumSize={13}
            spanDecoration="line-through"
          >
            De <span>R$177,00</span> por apenas 12x de R$9,74
          </Paragraph>
        </Left>
        <Right />
      </HeroBody>
    </Max>
  )
}

export default HeroSection
