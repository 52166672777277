import P from "components/P";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px 16px 0;
  width: 1284px;
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #E0E0E0;
  padding: 16px;
  margin-bottom: 16px;

  @media(min-width: 820px) {
    max-width: 45%;
  }

  @media(min-width: 1120px) {
    max-width: 30%;
  }
`

interface ItemImagePros {
  src: string
}

export const ItemImage = styled.div<ItemImagePros>`
  width: 120px;
  height: 150px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  background-image: ${({src}) => `url(${src})`};

  @media(min-width: 480px) {
    height: 120px;
  }

  @media(min-width: 715px) {
    height: 100px;
    width: 100px;
  }

  @media(min-width: 820px) {
    height: 160px;
    width: 100px;
  }
`

export const ItemLeft = styled.div`
  display: flex;
  margin-right: 27px;
`

export const ItemRight = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled(P)`
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 8px;
`