import React from 'react'
import { Card, Container, Image } from './styled'

interface DeliveriesCardProps {
  imageSrc: string
}

const DeliveriesCard: React.FC<DeliveriesCardProps> = ({ imageSrc }) => {
  return (
    <Container>
      <Card>
        <Image src={imageSrc} />
      </Card>
    </Container>
  )
}

export default DeliveriesCard
