import React from 'react'
import Button from 'components/Button'
import {
  Body,
  Description,
  Image,
  Left,
  PriceSimple,
  Right,
  SubDescription,
  Title,
  PayOncePriceDescription,
  Container,
  ShippingNotice,
} from './styled'
import { useMediaQuery } from '@react-hook/media-query'

interface ProductCardProps {
  image: string
  title?: string
  description?: string
  originalPrice?: string
  timesPrice?: string
  onePayPrice?: string
  buyButtonLabel?: string
  shippingNoticeLabel?: string
  onClickButton?: () => void
}

const ProductCard: React.FC<ProductCardProps> = ({
  image,
  title,
  description,
  originalPrice,
  timesPrice,
  onePayPrice,
  buyButtonLabel,
  shippingNoticeLabel,
  onClickButton,
}) => {
  const useTitleOnLeft = useMediaQuery('(max-width:438px)')

  return (
    <Container>
      <Body>
        <Left>
          {useTitleOnLeft && (
            <>
              <Title smallSize={20} mediumSize={20} largeSize={22} maxSize={22} margin="0">
                {title}
              </Title>
              <Description margin="0">{description}</Description>
            </>
          )}
          <Image src={image} />
        </Left>
        <Right>
          {!useTitleOnLeft && (
            <>
              <Title smallSize={20} mediumSize={20} largeSize={22} maxSize={22} margin="0">
                {title}
              </Title>
              <Description margin="0">{description}</Description>
            </>
          )}
          <SubDescription margin="0">
            De <span>{originalPrice}</span> por apenas
          </SubDescription>
          <PriceSimple smallSize={30} mediumSize={30} largeSize={30} maxSize={30}>
            <strong>12x</strong>
            {timesPrice}
          </PriceSimple>
          <PayOncePriceDescription margin="0">
            ou <span>{onePayPrice}</span> à vista
          </PayOncePriceDescription>
          <Button onClick={onClickButton}>{buyButtonLabel}</Button>
        </Right>
      </Body>
      <ShippingNotice>{shippingNoticeLabel}</ShippingNotice>
    </Container>
  )
}

export default ProductCard
