import styled from "styled-components";

interface MaxProps {
  color?: string
  useBlock?: boolean
}

const Max = styled.div<MaxProps>`
  display: ${({ useBlock }) => useBlock ? 'block' : 'flex'};
  justify-content: center;
  align-content: center;
  width: 100%;
  background-color: ${({ color }) => color ? color : '#FFFFFF'};
`;

export default Max;
