import React from 'react'
import { FiInstagram, FiMessageSquare } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import Max from 'components/Max'
import { Contact, Container, HelpSection, InfoSection, LinksSection, Title } from './styles'
import P from 'components/P'
import { tertiaryColor } from 'components/constants'
import goToAnchor from 'utils/goToAnchor'
import pagamento from 'images/lift-saude/pagamento-icon.png'

interface FooterProps {
  hideTelegram?: boolean
  basicVersion?: boolean
}

const Footer: React.FC<FooterProps> = ({ hideTelegram, basicVersion }) => {
  return (
    <footer>
      <Max color={tertiaryColor}>
        <Container>
          <HelpSection>
            <Title
              smallSize={38}
              mediumSize={38}
              largeSize={42}
              maxSize={42}
              margin="0 0 20px"
              secondary
            >
              Precisa de Ajuda?
            </Title>
            <Contact href="https://www.instagram.com/liftsaudebr/" target="_blank">
              <FiInstagram /> liftsaudebr
            </Contact>
            {!hideTelegram && (
              <Contact href="https://t.me/+4g8F52xTMtgyODUx" target="_blank">
                <FiMessageSquare /> t.me/+4g8F52x
              </Contact>
            )}
            <P smallSize={16} mediumSize={16} largeSize={16} maxSize={16} secondary>
              Nosso time de experiência de usuãrio está disponível 7 dias por semana de 9am-6pm.
              Mais canais de contato em breve!
            </P>
          </HelpSection>
          <LinksSection>
            {basicVersion ? (
              <>
                <Link to="/">Home</Link>
                <Link to="/politica-de-privacidade">Política de Privacidade</Link>
                <Link to="/termos-e-condicoes">Termos e condições</Link>
              </>
            ) : (
              <>
                <button type="button" onClick={() => goToAnchor('kit-items')}>
                  Ganhe 45% Off
                </button>
                <button type="button" onClick={() => goToAnchor('how-it-works')}>
                  Como Funciona
                </button>
                <button type="button" onClick={() => goToAnchor('about')}>
                  Fórmula
                </button>
                <button type="button" onClick={() => goToAnchor('member-result')}>
                  Testemunhas
                </button>
                <button type="button" onClick={() => goToAnchor('faq')}>
                  Perguntas Frequentes
                </button>
                <Link to="/politica-de-privacidade">Política de Privacidade</Link>
                <Link to="/termos-e-condicoes">Termos e condições</Link>
              </>
            )}
          </LinksSection>
          <InfoSection>
            <P secondary margin="0">
              Sua satisfação é garantida. Se não houver resultados em 30 dias você pode devolver!
              Nós nos comprometemos em devolver a quantia paga pelo seu kit de potes de Lift Detox
              Caps em caso de não resultados.
            </P>
            <P secondary margin="16px 0 10px">
              <b>Formas de Pagamento</b>
            </P>
            <img width={300} src={pagamento} alt="Formas de Pagamento" />
          </InfoSection>
        </Container>
      </Max>
    </footer>
  )
}

export default Footer
