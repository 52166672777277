import styled from "styled-components";
import P from "components/P";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 1284px;
  padding: 32px 16px;

  @media(min-width: 820px) {
    flex-direction: row;
    padding: 62px 24px;
  }

  @media(min-width: 1120px) {
    padding: 24px 56px;
  }
`;

export const Image = styled.img`
  width: 184px;
  height: 184px;
  border-radius: 30%;
  margin-bottom: 14px;

  @media(min-width: 820px) {
    border-radius: 0;
    display: block;
    margin-right: 16px;
    margin-bottom: 0;
    width: 417px;
    height: 417px;
  }

  @media(min-width: 1120px) {
    width: 516px;
    height: 516px;
    margin-right: 83px;
  }
`

export const DescriptionContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const DescTitle = styled(P)`
  font-weight: 500;
  margin: 0;
`