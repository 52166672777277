import React, { ReactChild } from 'react'
import anvisa from 'images/lift-detox/anvisa.png'
import natural from 'images/lift-detox/natural.png'
import garantee from 'images/lift-saude/30dias-garantia.png'
import { Container, InfoItemContainer } from './styles'
import Max from 'components/Max'
import P from 'components/P'

interface InfoItemProps {
  image: string
  alt: string
  children: ReactChild
}

const InfoItem: React.FC<InfoItemProps> = ({ image, alt, children }) => {
  return (
    <InfoItemContainer>
      <img src={image} alt={alt} />
      {children}
    </InfoItemContainer>
  )
}

const InfoBar: React.FC = () => {
  return (
    <Max color="#f5f5f5">
      <Container>
        <InfoItem image={anvisa} alt="Anvisa">
          <P smallSize={14} mediumSize={14} largeSize={14} maxSize={14}>
            <b>Lift Detox Caps é liberado e aprovado pela Anvisa</b> nos termos da RDC 240 de
            26/07/2018, sendo dispensado de registro, pois é avaliado como 100% Seguro para o
            consumo da população.
          </P>
        </InfoItem>
        <InfoItem image={natural} alt="Natural">
          <P smallSize={14} mediumSize={14} largeSize={14} maxSize={14}>
            Todos os Componentes da Fórmula <b>Lift Detox Caps são 100% Naturais,</b> sendo liberado
            para o uso sem preocupações como irritação e alergias, Garantindo mais segurança.
          </P>
        </InfoItem>
        <InfoItem image={garantee} alt="Garantia 30 Dias">
          <P smallSize={14} mediumSize={14} largeSize={14} maxSize={14}>
            <b>Emagrecimento comprovado!</b> É sério! Você fica satisfeito ou compramos o Lift Detox
            Caps de volta de você! Você terá até 30 dias após a compra para solicitar a devolução.
          </P>
        </InfoItem>
      </Container>
    </Max>
  )
}

export default InfoBar
