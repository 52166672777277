import React from 'react'
import { tertiaryColor } from 'components/constants'
import Max from 'components/Max'
import { Container, Image, LeftContent, Paragraph, RightContent, Title } from './styles'

const Tip: React.FC = () => {
  return (
    <Max color={tertiaryColor} id="tip">
      <Container>
        <LeftContent>
          <Title
            smallSize={26}
            mediumSize={26}
            largeSize={32}
            maxSize={42}
            secondary
            margin="16px 0 0"
          >
            Fim da Guerra Contra a Balança
          </Title>
          <Paragraph secondary margin="8px 0 24px">
            Você sabia que para ter resultados surpreendentes e muito mais rápidos é necessário
            fazer o uso de 2 cápsulas por dia?
          </Paragraph>
          <Paragraph secondary margin="8px 0 24px">
            Sim, é verdade para conseguir resultados incríveis com Lift Detox Caps basta tomar as
            cápsulas 40 minutos antes das principais refeições.
          </Paragraph>
          <Paragraph secondary margin="8px 0 24px">
            <strong>
              Com uma fórmula altamente concentrada e ativos rigorosamente selecionados Lift Detox
              Caps auxilia na jornada do corpo ideal.
            </strong>
          </Paragraph>
        </LeftContent>
        <RightContent>
          <Image />
        </RightContent>
      </Container>
    </Max>
  )
}

export default Tip
