import { ReactChild } from "react";
import ReactDOM from "react-dom";

interface Props {
  children: ReactChild;
}

/**
 * Allows adding a child in a node above the app root element. This is useful to overlay the main site content.
 * Can be used with Modals and others.
 *
 * This feature will also avoid the nested "<button type"submit"... />" issue. The issue happens when we have
 * a Parent component with a submit button, and this Parent component has a child component with a
 * submit button as well. When the user clicks in any of these submit buttons, the "onSubmit" event
 * will be dispatched for both, Parent's submit and Child's submit button.
 *
 * Example:
 * import GlobalPortal from '@containers/GlobalPortal';
 *
 * <GlobalPortal>
 *    <MyModal {...props} />
 * </GlobalPortal>
 *
 * @param {{ children: React.ElementType | Node }} param
 * @returns
 */
const GlobalPortal = ({ children }: Props) => {
  const globalReactPortal = document.getElementById(
    "global-react-portal"
  ) as Element;
  return ReactDOM.createPortal(children, globalReactPortal);
};

export default GlobalPortal;
