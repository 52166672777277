import styled from 'styled-components'
import { fontFamily } from './constants'

interface InputProps {
  uppercase?: boolean
}

const Input = styled.input<InputProps>`
  border-radius: 5px;
  border: 1px solid rgb(224, 224, 224);
  caret-color: rgb(237, 101, 75);
  font-weight: 400;
  line-height: 28px;
  font-size: 16px;
  height: 48px;
  color: rgb(33, 33, 33);
  padding: 0 10px;
  outline: none;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
  font-family: ${fontFamily};
`
export default Input
