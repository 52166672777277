import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useMediaQuery } from '@react-hook/media-query'
import useOutsideClick from 'hooks/useOutsideClick'
import { Button, Links, Wrapper } from './styles'

/**
 * Renders a Header's dropdown menu and its items
 * Ex:
 *
 * const myDropdown = {
 *   label: 'My Dropdown',
 *   to: '/store', // - optional
 *   hideForMobile: false, // - optional
 *   key: 'my-dropdown',
 *   items: [
 *    {label: 'Option 1', to: '/op1pageroute', key: 'op1'},
 *    {label: 'Option 2', to: '/op2pageroute', key: 'op3'},
 *    {label: 'Option 3', to: '/op2pageroute', key: 'op3'},
 *   ]
 * };
 *
 * ...
 * return (<Dropdown {...myDropdown}/>)
 * ...
 */

interface DropdownProps {
  label: string
  to?: string
  items: { label: string; to: string; key: string }[]
  hideForMobile?: boolean
}

const Dropdown: React.FC<DropdownProps> = ({ label, to, items, hideForMobile }) => {
  const [show, setShow] = useState(false)
  const aboutBoxRef = useRef<HTMLDivElement | null>(null)
  const navigate = useNavigate()
  const isMobile = useMediaQuery('(max-width:873px)')

  useOutsideClick(aboutBoxRef, () => {
    setShow(false)
  })

  const getLinks = () => (
    <Links onMouseLeave={() => setShow(false)}>
      {items.map(({ label, to, key }) => (
        <Link to={to} key={key}>
          {label}
        </Link>
      ))}
    </Links>
  )

  return (
    <>
      {hideForMobile && !isMobile && (
        <Wrapper ref={aboutBoxRef} onMouseLeave={() => setShow(false)}>
          <Button
            active={show}
            onMouseEnter={() => setShow(!isMobile && true)}
            {...(to ? { onClick: () => navigate(to) } : {})}
          >
            {label}
          </Button>
          {show && getLinks()}
        </Wrapper>
      )}
    </>
  )
}

export default Dropdown
