import React from 'react'
import { Author, Card, Container, Description, Image } from './styled'

interface MemberResultCardProps {
  imageSrc: string
  horizontalImageAdjustment?: number
  description: string
  author: string
  age: number
  kgLost: number
}

const MemberResultCard: React.FC<MemberResultCardProps> = ({
  imageSrc,
  horizontalImageAdjustment,
  description,
  author,
  age,
  kgLost,
}) => {
  return (
    <Container>
      <Card>
        <Image src={imageSrc} horizontalAdjustment={horizontalImageAdjustment} />
        <Description smallSize={14} mediumSize={14} largeSize={16} maxSize={16}>
          ”{description}”
        </Description>
        <Author smallSize={14} mediumSize={14} largeSize={16} maxSize={16}>
          {author} - {age} Anos <br />
          Perdeu {kgLost}Kg
        </Author>
      </Card>
    </Container>
  )
}

export default MemberResultCard
