import { useEffect, useState } from "react";

const useOnResize = (callback: () => void, checkhorizontallyOnly: boolean = false) => {
  const [widthArea, setWidthArea] = useState(window.innerWidth);

  useEffect(() => {
    const resizeHandler = () => {
      if(checkhorizontallyOnly) {
        if (widthArea !== window.innerWidth) {
          callback();
          setWidthArea(window.innerWidth);
        }
      } else {
        callback();
      }
    }

    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    }
  }, [callback, checkhorizontallyOnly, widthArea])
}

export default useOnResize;
