import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1284px;
  background-color: #f5f5f5;
  padding: 50px 16px;

  @media(min-width: 1215px) {
    justify-content: space-around;
    flex-direction: row;
  }
`

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 80%;

  & img {
    height: 95px;
    align-self: center;
  }

  & p {
    text-align: center;
    margin-bottom: 0;
  }

  margin-bottom: 38px;

  &:last-child {
    margin-bottom: 0;
  }

  @media(min-width: 1215px) {
    margin-bottom: 0;
    max-width: 30%;
    margin-bottom: 0;
  }
`