import styled from 'styled-components'
import { fontFamily, textPrimaryColor, textSecondaryColor } from './constants'

interface ParagraphProps {
  secondary?: boolean
  margin?: string
  smallSize?: number
  mediumSize?: number
  largeSize?: number
  maxSize?: number
  spanDecoration?: "line-through" | "overline" | "underline"
  align?: "left" | "center" | "right"
}

const P = styled.p<ParagraphProps>`
  text-align: ${({ align }) => align || ''};
  color: ${({ secondary }) => secondary ? textSecondaryColor : textPrimaryColor};
  font-weight: ${({ smallSize }) => {
    const defaultSize = smallSize || 14
    if (defaultSize < 14) return '500'
    return '400'
  }};
  line-height: 24px;
  margin: ${({margin}) => margin};
  font-family: ${fontFamily};

  & span {
    text-decoration: ${({ spanDecoration }) => spanDecoration};
  }

  font-size: ${({ smallSize }) => smallSize ? `${smallSize}px` : '14px'};

  @media(min-width: 375px) {
    font-size: ${({ mediumSize }) => mediumSize ? `${mediumSize}px` : '14px'};
    line-height: 24px;
  }

  @media(min-width: 820px) {
    font-size: ${({ largeSize }) => largeSize ? `${largeSize}px` : '16px'};
    line-height: 28px;
  }

  @media(min-width: 1120px) {
    font-size: ${({ maxSize }) => maxSize ? `${maxSize}px` : '16px'};
  }
`
export default P
