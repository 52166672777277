import styled from "styled-components";
import H2 from "components/H2";
import P from "components/P";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1284px;
  padding: 34px 34px;
`

export const Title = styled(H2)`
  font-weight: 900;
  line-height: 30px;
`

export const Paragraph = styled(P)`
  margin: 8px 0;
`