import React from 'react'
import LandingPage from 'pages/Landing'
import PolicyPrivacy from 'pages/PolicyPrivacy'
import TermsAndConditions from 'pages/TermsAndConditions'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import 'firebaseApp'

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/politica-de-privacidade" element={<PolicyPrivacy />} />
        <Route path="/termos-e-condicoes" element={<TermsAndConditions />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
