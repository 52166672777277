import React from 'react'
import Max from 'components/Max'
import MemberResultCard from 'components/MemberResultCard'
import { Container, Title } from './styled'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './carousel.css'

import dp1 from 'images/lift-detox/dp-1.jpg'
import dp2 from 'images/lift-detox/dp-2.jpg'
import dp3 from 'images/lift-detox/dp-3.jpg'

const MemberResult: React.FC = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 9000, min: 1301 },
      items: 3, // Coloca 4 caso tenha mais cards
    },
    desktop: {
      breakpoint: { max: 1301, min: 994 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 994, min: 670 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 670, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  }

  return (
    <Max id="member-result">
      <Container>
        <Title smallSize={18} mediumSize={18} largeSize={24} maxSize={24}>
          150.000+ pessoas satisfeitas
        </Title>
        <Carousel showDots={true} responsive={responsive}>
          <MemberResultCard
            imageSrc={dp1}
            description="Hoje eu completo 7 meses que comecei a tomar, perdi 16kg e estou muito satisfeita com meu
        corpo!"
            author="Carla F."
            age={33}
            kgLost={16}
          />

          <MemberResultCard
            imageSrc={dp2}
            description="Depois de tentar de tudo, finalmente encontrei a maneira correta de emagrecer, super feliz com os resultados."
            author="Sandra B."
            age={30}
            kgLost={12}
          />

          <MemberResultCard
            imageSrc={dp3}
            description="Nossa, estou maravilhada com o Lift Detox Caps. Nunca pensei que poderia perder peso tão facilmentes."
            author="Millena S."
            age={24}
            kgLost={9}
          />
        </Carousel>
      </Container>
    </Max>
  )
}

export default MemberResult
